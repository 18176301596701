// noinspection SpellCheckingInspection

import GORoundLogo from '../../../../assets/images/goRoundLogo.svg';
import QBRoundLogo from '../../../../assets/images/QBLogo.svg';
import LinkProgressLogo from '../../../../assets/images/linkingprogress.svg';

import blacklogo from '../../../../assets/images/blacklogo.svg';

import axios from '../../../../utils/axios';
import {
    AlignItems,
    Box,
    Button,
    ButtonVariant,
    Color,
    FontWeight,
    JustifyContent,
    P,
    Text,
    XYGrid,
    ButtonSize,
    ProgressBar,
    ProgressBarVariant,
    ColorScheme,
    TypeScale,
    Space,
    Select,
    Option,
    Toast,
    BannerVariant,
    BannerStatus,
    useToast
} from '@gocardless/flux-react';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {useNavigate} from 'react-router';

// ----------------------------------------------------------------------

export default function Hero() {
    const location:any = useLocation();
    const max = 3;
    const {toast, dismissToast} = useToast();

    const navigate = useNavigate();
    const value = 1;

    const [gclFeesState, setGclFreesState] = React.useState<any>({
        GoCardlessCrossCurrencyTransfers: [],
        GoCardlessFees: [],
        QuickbooksCurrencySettingsAccount: []
    });

    const [selectedValues, setSelectedValues] = React.useState<any>(
      location?.state && Object.keys(location.state).includes('selectedValues')
        ? location.state.selectedValues
        : {
            GoCardlessAccountId: '',
            GoCardlessFeesAccountId: '',
            GoCardlessCrossCurrencyTransferAccountId: ''
          }
    );

    const onChangeSelect = (e) =>
        setSelectedValues({...selectedValues, [e.target.name]: e.target.value});
    const checkIfKeyExist = (objectName, keyName) =>
        Object.keys(objectName).some((key) => key === keyName);


    const setValueToSelect = () => location?.state &&
        [
            'GoCardlessCrossCurrencyTransfers',
            'GoCardlessFees',
            'QuickbooksCurrencySettingsAccount'
        ].map((key) => checkIfKeyExist(location?.state, key)).some((d) => d !== false) &&
        setGclFreesState(location.state);

    const ToastGcl = (title, message, status) => toast((t) => (
        <Toast id={`toast_${t.id}`}
               title={title}
               variant={BannerVariant.Light}
               status={status}
               closeAction={{
                   onClose: () => dismissToast(t.id),
                   label: 'Close'
               }}>
            {message}
        </Toast>
    ));
    const validatePayload = (): string[] => {
        let Error: string[] = [];
        if ((gclFeesState?.QuickbooksCurrencySettingsAccount.length !== 0 && !('GoCardlessAccountId' in selectedValues) )
            || selectedValues.GoCardlessAccountId === '' && gclFeesState?.QuickbooksCurrencySettingsAccount.length !== 0)
            Error.unshift('Please select bank account');
        if ((gclFeesState?.GoCardlessFees.length !== 0 && !('GoCardlessFeesAccountId' in selectedValues) )
            || selectedValues.GoCardlessFeesAccountId === '' && gclFeesState?.GoCardlessFees.length !== 0 )
            Error.unshift('Please Select Fee account');
        if ((gclFeesState?.GoCardlessCrossCurrencyTransfers.length !== 0 && !('GoCardlessCrossCurrencyTransferAccountId' in selectedValues))
            || selectedValues.GoCardlessCrossCurrencyTransferAccountId === '' && gclFeesState?.GoCardlessCrossCurrencyTransfers.length !== 0)
            Error.unshift('Please select cross-currency transfers account');
        return Error;
    };
    const SaveQuickbooksReconciliation = async () => {
        const validateResult: string[] = validatePayload();
        if (validateResult.length !== 0) return validateResult.map((msg:string)=>ToastGcl('Error',msg, BannerStatus.Alert))
        try {
            const token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(
                '/setting/saveQuickbooksReconciliation',
                {
                    quickbookReconciliation: selectedValues
                }
            );
            if (response) {
                ToastGcl('Success', "Setting updated", BannerStatus.Success);
                // @ts-ignore
                return navigate('/customer-onboarding/request-authorization', {state: {...location.state,selectedValues:selectedValues}});
            }
        } catch (e) {
            console.log('error', e);
        }
    };

    useEffect(() => {
        setValueToSelect();
    }, []);
    return (
        <XYGrid
            templateColumns="repeat(12, 1fr)"
            templateRows="repeat(2)"
            columnGap={1}
            rowGap={1}
            height={'100%'}
        >
            <Box
                gridColumn={['span 12', null, null, 'span 8']}
                layout="flex"
                justifyContent={JustifyContent.Center}

            >
                <Box>
                    <Box spaceAbove={2} spaceBefore={1}>
                        <img src={blacklogo} alt={'blacklogo'}/>
                    </Box>
                    <Box height="90%" borderRadius={1} layout="flex">
                        <Box spaceBefore={1} spaceAfter={1}>
                            <Box
                                spaceBelow={1}
                                spaceAbove={2}
                                maxWidth={['100%', null, '200px']}
                            >
                                <ProgressBar
                                    borderRadius={0}
                                    variant={ProgressBarVariant.Solid}
                                    colorScheme={ColorScheme.OnLight}
                                    value={value}
                                    max={max}
                                    label={
                                        <Text id="radiusDemo2">
                                            <P weight={400}
                                               className={'customer-onboarding-progress-color'}
                                               fontFamily={"sans-serif"}>Step {value} of {max}</P>
                                        </Text>
                                    }
                                    aria-labelledby="radiusDemo2"
                                />
                            </Box>
                            {/*<Space/>*/}
                            <Box spaceBelow={2} className={"m-26"}>
                                <P size={7} weight={600} fontFamily={"sans-serif"} className={'login-header-text'}>
                                    GoCardless fees
                                </P>
                            </Box>

                            <Box
                                layout="flex"
                                justifyContent={JustifyContent.Center}
                                alignItems={AlignItems.Center}
                                spaceBelow={1}
                                flexDirection={['column', null, 'row']}
                                maxWidth={['100%', null, '800px']}
                            >
                                <P size={3} weight={400}
                                   fontFamily={"sans-serif"}
                                   className={'customer-onboarding-gray-title-color'}>
                                    Your invoices will be automatically matched and assigned to
                                    the correct bank and expense accounts in Quickbooks:
                                </P>
                            </Box>
                            <Space v={1}/>
                            <Box maxWidth={['100%', null, '750px']}>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_04}
                                    weight={FontWeight.SemiBold}
                                    fontFamily="sans-serif"
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Bank account (AUD)
                                </P>
                                <span />
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_03}
                                    fontFamily="sans-serif"
                                    // color={Color.Greystone_DarkMatter}
                                    className={'customer-onboarding-title-color'}
                                >
                                    The account you want GoCardless to pay funds to
                                </P>
                                <Space v={1}/>
                                <Select
                                    id="mainCourse"
                                    name="GoCardlessAccountId"
                                    onChange={onChangeSelect}
                                    value={selectedValues.GoCardlessAccountId}
                                >
                                    <Option value="">Select bank account</Option>
                                    {gclFeesState?.QuickbooksCurrencySettingsAccount?.map(
                                        (d, i) => (
                                            <Option value={d.Id} key={i}>
                                                {d.Name}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            </Box>
                            <Space v={2}/>

                            <Box maxWidth={['100%', null, '750px']}>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_04}
                                    weight={FontWeight.SemiBold}
                                    fontFamily="sans-serif"
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Fee account
                                </P>
                                <Space v={1}/>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_03}
                                    fontFamily="sans-serif"
                                    // color={Color.Greystone_DarkMatter}
                                    className={'customer-onboarding-title-color'}

                                >
                                    Your fee account should be the expense account (with no tax
                                    type) in QuickBooks where you wish to post GoCardless fees
                                </P>
                                <Space v={1}/>
                                <Select
                                    id="mainCourse"
                                    name="GoCardlessFeesAccountId"
                                    onChange={onChangeSelect}
                                    value={selectedValues.GoCardlessFeesAccountId}
                                >
                                    <Option value="">Select fee account</Option>
                                    {gclFeesState?.GoCardlessFees?.map((d, i) => (
                                        <Option value={d.Id} key={i}>
                                            {d.Name}
                                        </Option>
                                    ))}
                                </Select>
                            </Box>
                            <Space v={2}/>

                            <Box maxWidth={['100%', null, '750px']}>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_04}
                                    weight={FontWeight.SemiBold}
                                    fontFamily="sans-serif"
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Cross-Currency Transfers account
                                </P>
                                <Space v={1}/>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_03}
                                    fontFamily="sans-serif"
                                    // color={Color.Greystone_DarkMatter}
                                    className={'customer-onboarding-title-color'}

                                >
                                    This option is important if you collect payments
                                    internationally. This account should be an account you want to
                                    use to process cross-currency transfers
                                </P>
                                <Space v={1}/>
                                <Select
                                    id="mainCourse"
                                    name="GoCardlessCrossCurrencyTransferAccountId"
                                    onChange={onChangeSelect}
                                    value={selectedValues.GoCardlessCrossCurrencyTransferAccountId}
                                >
                                    <Option value="">Select cross-currency transfers account</Option>
                                    {gclFeesState?.GoCardlessCrossCurrencyTransfers?.map(
                                        (d, i) => (
                                            <Option value={d.Id} key={i}>
                                                {d.Name}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            </Box>
                            <Space v={2}/>
                            <Button
                                variant={ButtonVariant.PrimaryOnLight}
                                size={ButtonSize.Sm}
                                // onClick={onGclLoign}
                                className={'m-8'}

                                onClick={SaveQuickbooksReconciliation}
                            >
                                Continue
                            </Button>
                            <Space v={3}/>

                            <Box maxWidth={['100%', null, '750px']} className={'m-8'}>
                                <P
                                    lineHeight={TypeScale.Size_04}
                                    size={TypeScale.Size_04}
                                    weight={FontWeight.SemiBold}
                                    fontFamily="sans-serif"
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Get support
                                </P>
                                {/*<Space v={}/>*/}
                                <P
                                    lineHeight={TypeScale.Size_02}
                                    size={TypeScale.Size_02}
                                    fontFamily="sans-serif"
                                    weight={400}
                                    // color={Color.Greystone_DarkMatter}
                                    className={'customer-onboarding-gray-title-color mt-13'}
                                >
                                    GoCardless is a Bacs approved bureau and is authorized by the
                                    Financial Conduct Authority under the Payment Services
                                    Regulations 2009, registration number 597190, for the
                                    provision of payment services
                                </P>
                            </Box>

                            <Space v={2}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                gridColumn={['span 12', null, null, 'span 4']}
                height="100%"
                width={[null,null,null,"623px"]}
                minHeight={'30vh'}
                bg={Color.Evergreen_1400}
            >
                <Box
                    layout="flex"
                    height="70%"
                    justifyContent={JustifyContent.Center}
                    alignItems={AlignItems.Center}
                >
                    <Box spaceAfter={1}>
                        <img src={QBRoundLogo} height={'83px'} alt={'QBRoundLogo'}/>
                    </Box>

                    <Box spaceAfter={1}>
                        <img
                            src={LinkProgressLogo}
                            width={'103px'}
                            alt={'LinkProgressLogo'}
                        />
                    </Box>
                    <Box>
                        <img src={GORoundLogo} height={'83px'} alt={'GORoundLogo'}/>
                    </Box>
                </Box>
            </Box>
        </XYGrid>
    );
}
