import {Suspense, lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from './layouts/SidebarLayout';
import RouteGuest from './components/RouteGuest';
import CustomerOnboarding from './pages/customer-onboarding/GoCardless-fees';
import RequestAuthorization from './pages/customer-onboarding/Request-type';
import CollectPayments from './pages/customer-onboarding/collect-payments';
import Account from "./pages/account";

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<></>}>
            <Component {...props} />
        </Suspense>
    );

// Pages
const Login = Loader(lazy(() => import('src/pages/Login')));
const Dashboard = Loader(lazy(() => import('src/pages/dashboard')));
const Invoices = Loader(lazy(() => import('src/pages/invoices')));
const BulkInvoices = Loader(lazy(() => import('src/pages/bulk-invoices')));
const Reconciliation = Loader(lazy(() => import('src/pages/reconciliation')));
const ReconciliationReports = Loader(
    lazy(() => import('src/pages/reconciliation-reports'))
);
const Success = Loader(lazy(() => import('src/pages/success+')));
const Refunds = Loader(lazy(() => import('src/pages/refunds')));
// Customer Page
const Customers = Loader(lazy(() => import('src/pages/customers')));
const CustomersInactive = Loader(
    lazy(() => import('src/pages/customer-inactive'))
);
const CustomersMatch = Loader(lazy(() => import('src/pages/customer-match')));
// Setting Page
const ConnectedApps = Loader(
    lazy(() => import('src/pages/Settings/connectedApps'))
);
const Automation = Loader(lazy(() => import('src/pages/Settings/automation')));
const Reconciliation_ = Loader(
    lazy(() => import('src/pages/Settings/reconciliation'))
);
const General = Loader(lazy(() => import('src/pages/Settings/general')));
const Teams = Loader(lazy(() => import('src/pages/Settings/teams')));
// Loding Pages
const LoginWithQBO = Loader(
    lazy(() => import('src/pages/Loadings/loginWithQBO'))
);
const LoginWithGCL = Loader(
    lazy(() => import('src/pages/Loadings/loginWithGCL'))
);
const NewCustomer = Loader(
    lazy(() => import('src/pages/Loadings/NewCustomer'))
);
const LaunchApp = Loader(
    lazy(() => import('src/pages/launchapp/Hero'))
);
const DisconnectApp = Loader(
    lazy(() => import('src/pages/disconnectapp/Hero'))
);

const routes: RouteObject[] = [
    {
        path: '/',
        element: <BaseLayout/>,
        children: [
            {
                path: '',
                element: (
                    <RouteGuest>
                        <Login/>
                    </RouteGuest>
                )
            },
            {
                path: 'login',
                element: <Navigate to="/" replace/>
            },
            {
                path: 'loginwithQBO',
                element: <LoginWithQBO/>
            },
            {
                path: 'loginwithGCL',
                element: <LoginWithGCL/>
            },
            {
                path: 'customer-onboarding/GoCardless-fees',
                element: <CustomerOnboarding/>
            },
            {
                path: 'customer-onboarding/request-authorization',
                element: <RequestAuthorization/>
            },
            {
                path: 'customer-onboarding/collect-payments',
                element: <CollectPayments/>
            },
            {
                path: '/pay/co/*',
                element: <NewCustomer to="/" replace/>
            },
            {
                path:'/launchapp',
                element:<LaunchApp></LaunchApp>
            },
            {
                path:`/disconnectapp`,
                element:<DisconnectApp/>
            }
        ]
    },
    {
        path: '',
        element: (
            // <RouteGuard>
            <SidebarLayout/>
            // </RouteGuard>
        ),
        children: [
            {
                path: '/dashboard',
                element: <Dashboard/>
            },
            {
                path: '/account',
                element: <Account/>
            },
            {
                path: '/success',
                element: <Success/>
            },
            {
                path: '/refunds',
                element: <Refunds/>
            }
        ]
    },
    {
        path: 'customers',
        element: <SidebarLayout/>,
        children: [
            {
                path: '',
                element: <Customers/>
            },
            {
                path: 'inactive',
                element: <CustomersInactive/>
            },
            {
                path: 'match',
                element: <CustomersMatch/>
            }
        ]
    },
    {
        path: 'invoices',
        element: <SidebarLayout/>,
        children: [
            {
                path: '',
                element: <Invoices/>
            },
            {
                path: 'bulk-invoices',
                element: <BulkInvoices/>
            }
        ]
    },
    {
        path: 'reconciliation',
        element: <SidebarLayout/>,
        children: [
            {
                path: '',
                element: <Reconciliation/>
            },
            {
                path: 'reports/:id',
                element: <ReconciliationReports/>
            }
        ]
    },
    {
        path: 'settings',
        element: <SidebarLayout/>,
        children: [
            {
                path: '',
                element: <Navigate to="quickbooks/configuration" replace/>
            },
            {
                path: 'quickbooks/connected-apps',
                element: <ConnectedApps/>
            },
            {
                path: 'quickbooks/automation',
                element: <Automation/>
            },
            {
                path: 'quickbooks/reconciliation',
                element: <Reconciliation_/>
            },
            {
                path: 'gocardless/general',
                element: <General/>
            },
            {
                path: 'gocardless/teams',
                element: <Teams/>
            }
        ]
    }
];

export default routes;
