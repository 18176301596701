export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const thousandSeparator = (value) => {
  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
  return nf.format(Number(value));
};

export const isMobile = (): Boolean =>
  screen.width <= 640 ||
  (window.matchMedia &&
    window.matchMedia('only screen and (max-width: 640px)').matches);
