import {useLocation, useNavigate} from 'react-router-dom';
import blacklogo from '../../../../assets/images/blacklogo.svg';
import collect1 from '../../../../assets/images/collect1.png';
import manually1 from '../../../../assets/images/manually1.png';
import PaymentImage from '../../../../assets/images/6QB2.png';
import axios from '../../../../utils/axios';
import {
    BannerStatus,
    BannerVariant,
    Box,
    Button,
    ButtonSize,
    ButtonVariant,
    Color,
    ColorScheme,
    FieldSet,
    FontWeight,
    Glyph,
    Icon,
    JustifyContent,
    P,
    PlainLink,
    ProgressBar,
    ProgressBarVariant,
    Radio,
    Space,
    Text,
    Toast,
    ToggleControlPosition,
    ToggleVariant,
    TypeScale,
    useToast,
    XYGrid
} from '@gocardless/flux-react';
import React from 'react';

// ----------------------------------------------------------------------

export default function Hero() {
    const location = useLocation();
    const navigate = useNavigate();
    const [automaticRequest, setAutomaticRequest] = React.useState('true');

    const max = 3;
    const value = 3;
    const { toast, dismissToast } = useToast();

    const ToastGcl = (title, message, status) =>
        toast((t) => (
            <Toast
                id={`toast_${t.id}`}
                title={title}
                variant={BannerVariant.Light}
                status={status}
                closeAction={{
                    onClose: () => dismissToast(t.id),
                    label: 'Close'
                }}
            >
                {message}
            </Toast>
        ));
    const TakePaymentAutomatically = async (): Promise<void> => {
        try {
            const token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post('/setting/saveQuickbooksAutomation', {
                quickbookAutomation: {
                    IsTakePaymentAutomaticallyOnTheInvoiceDueDate: automaticRequest === 'true'
                }
            });
            if (response) {

                const token = localStorage.getItem('token');
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                await axios.post('/auth/oneTimeSync',);
                ToastGcl('Success', 'Setting updated', BannerStatus.Success);
                return navigate('/dashboard');
            }
        } catch (err: any) {
            console.log(err.message);
        }
    };
    return (
        <XYGrid
            templateColumns="repeat(12, 1fr)"
            templateRows="repeat(2)"
            columnGap={1}
            rowGap={1}
            height={'100%'}
        >
            <Box
                gridColumn={['span 12', null, null, 'span 8']}
                layout="flex"
                justifyContent={JustifyContent.Center}
            >
                <Box>
                    <Box spaceAbove={2} spaceBefore={1}>
                        <img src={blacklogo} alt={'blacklogo'} />
                    </Box>
                    <Box height="90%" borderRadius={1} layout="flex">
                        <Box spaceBefore={1} spaceAfter={1}>
                            <Box
                                spaceBelow={1}
                                spaceAbove={2}
                                maxWidth={['100%', null, '200px']}
                            >
                                <ProgressBar
                                    borderRadius={0}
                                    variant={ProgressBarVariant.Solid}
                                    colorScheme={ColorScheme.OnLight}
                                    value={value}
                                    max={max}
                                    label={
                                        <Text id="radiusDemo2">
                                            <P weight={400}
                                                className={'customer-onboarding-progress-color'}
                                                fontFamily={"sans-serif"}>Step {value} of {max}</P>
                                        </Text>
                                    }
                                    aria-labelledby="radiusDemo2"
                                />
                            </Box>
                            <Box spaceBelow={2} maxWidth={['100%', null, '800px']} className={"m-26"}>
                                <P size={7} weight={600} fontFamily="sans-serif" className={'login-header-text'}>
                                    Choose how you collect payments
                                </P>
                            </Box>

                            <Box
                                spaceBelow={1}
                                flexDirection={['column', null, 'row']}
                                maxWidth={['100%', null, '800px']}
                            >
                                <FieldSet className={'radio-fieldset'}>
                                    <Radio
                                        controlPosition={ToggleControlPosition.Start}
                                        variant={ToggleVariant.Emphasized}
                                        name="controlPosition"
                                        checked={automaticRequest === "true"}
                                        value="true"
                                        onClick={(e: any) => setAutomaticRequest(e.target?.value)}
                                        description="Collection will be automatically scheduled on the invoice due date"
                                    >
                                        <XYGrid
                                            templateColumns="repeat(12, 1fr)"
                                            templateRows="repeat(2)"
                                            columnGap={1}
                                            rowGap={1}
                                            height={'100%'}
                                        >
                                            <Box gridColumn={['span 9', null, null, 'span 9']}>
                                                <Text color={Color.Evergreen_800}>Recommended</Text>
                                                <Space v={1} />
                                                <P size={6} fontFamily="sans-serif">
                                                    {' '}
                                                    Collect payments automatically on the invoice
                                                    due date
                                                </P>
                                                <Space v={1} />
                                            </Box>
                                            <Box gridColumn={['span 3', null, null, 'span 3']} layout={'flex'} justifyContent={JustifyContent.Center}>
                                                <img height="120px" src={collect1} alt="collect1" className={"img117px"} />
                                            </Box>
                                        </XYGrid>
                                    </Radio>
                                    <Radio
                                        controlPosition={ToggleControlPosition.Start}
                                        variant={ToggleVariant.Emphasized}
                                        name="controlPosition"
                                        checked={automaticRequest === "false"}
                                        value="false"
                                        onClick={(e: any) => setAutomaticRequest(e.target?.value)}
                                    >
                                        <XYGrid
                                            templateColumns="repeat(12, 1fr)"
                                            templateRows="repeat(2)"
                                            columnGap={1}
                                            rowGap={1}
                                            height={'100%'}
                                        >
                                            <Box gridColumn={['span 9', null, null, 'span 9']}>
                                                <P size={6} fontFamily="sans-serif"> Collect
                                                    payments manually  <P className={'hide-pera'}>______________------------____________________</P></P>
                                                {/*<Space v={1}/>*/}

                                                <P size={3}
                                                    fontFamily="sans-serif" className={'customer-onboarding-title-color manually-schedule'}
                                                    weight={FontWeight.Light}>
                                                    Manually schedule payment collection for your
                                                    invoices
                                                </P>
                                            </Box>
                                            <Box gridColumn={['span 3', null, null, 'span 3']} layout={'flex'} justifyContent={JustifyContent.Center}>
                                                <img height="97px" width={'97px'} src={manually1} alt="manually1" />
                                            </Box>
                                        </XYGrid>
                                    </Radio>
                                </FieldSet>
                            </Box>
                            <Space v={1} />

                            <Box maxWidth={['100%', null, '750px']}>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_03}
                                    fontFamily="sans-serif"
                                    weight={400}
                                    color={Color.Greystone_DarkMatter}
                                    className={'customer-onboarding-gray-title-color'}
                                >
                                    You can change these settings at any time. Your customer is
                                    always notified before payment is collected.
                                </P>
                            </Box>
                            <Space v={3} />

                            <Button
                                variant={ButtonVariant.PrimaryOnLight}
                                size={ButtonSize.Sm}
                                onClick={TakePaymentAutomatically}
                            >
                                Finish
                            </Button>

                            <Space v={2} />
                            <Box layout="flex" >
                                <P>
                                    <Icon size="20px" className={'title-color'} name={Glyph.ChevronLeft} />
                                </P>{' '}
                                <PlainLink
                                    href="javascript:void(0);"
                                    decoration={'underline'}
                                    weight={FontWeight.Bold}
                                    onClick={() => navigate('/customer-onboarding/request-authorization',{state:location.state})}
                                    className={'title-color'}
                                    fontFamily={'sans-serif'}
                                >
                                    Back
                                </PlainLink>
                            </Box>
                            <Space v={2} />
                            <Box maxWidth={['100%', null, '750px']} >
                                <P
                                    lineHeight={TypeScale.Size_04}
                                    size={TypeScale.Size_04}
                                    weight={FontWeight.SemiBold}
                                    fontFamily="sans-serif"
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Get support
                                </P>


                                <P
                                    lineHeight={TypeScale.Size_02}
                                    size={TypeScale.Size_02}
                                    fontFamily="sans-serif"
                                    weight={400}
                                    // color={Color.Greystone_DarkMatter}
                                    className={'customer-onboarding-gray-title-color  mt-13'}
                                >
                                    GoCardless is a Bacs approved bureau and is authorized by the
                                    Financial Conduct Authority under the Payment Services
                                    Regulations 2009, registration number 597190, for the
                                    provision of payment services
                                </P>
                                <Space v={2} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                gridColumn={['span 12', null, null, 'span 4']}
                height="100%"
                width={[null, null, null, "623px"]}
                bg={Color.Evergreen_1400}
            >
                <img
                    src={PaymentImage}
                    alt="PaymentImage"
                    height={'100%'}
                    width={'100%'}
                ></img>
            </Box>
        </XYGrid>
    );
}
